import { Offcanvas, Modal, Tooltip } from 'bootstrap';
import axios from 'axios';
import cookieconsent from 'vanilla-cookieconsent';

window.axios = axios;
window.bsModal = Modal;

// get closest parent matching selector
// -----------------------------------------------------------------------------
window.getClosest = function(el, selector) {
	for (; el && el !== document; el = el.parentNode) {
		if (el.matches(selector) ) return el;
	}
	return null;
};

window.formatMoney = function(value) {
	return "€ " + value.toLocaleString('it-IT', {
		minimumFractionDigits : 2,
		maximumFractionDigits : 2
	});
};

(function() {

	// DOMContentLoaded
	//--------------------------------------------------------------------------
	document.addEventListener("DOMContentLoaded", ( e ) => {

		// Init Tooltips
		
		const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
		const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

		if(!window.cc_hide) {

			// obtain cookieconsent plugin
			const cookieconsent = initCookieConsent();
			cookieconsent.run({
				current_lang : 'it',
		        autoclear_cookies : true,                   // default: false
		        cookie_name: 'cc_cookie_consent',             // default: 'cc_cookie'
		        cookie_expiration : 365,                    // default: 182
		        page_scripts: true,                         // default: false
		        force_consent: true,

		        // auto_language: null,                     // default: null; could also be 'browser' or 'document'
		        // autorun: true,                           // default: true
		        // delay: 0,                                // default: 0
		        // force_consent: false,
		        // hide_from_bots: false,                   // default: false
		        // remove_cookie_tables: false              // default: false
		        // cookie_domain: location.hostname,        // default: current domain
		        // cookie_path: "/",                        // default: root
		        // cookie_same_site: "Lax",
		        // use_rfc_cookie: false,                   // default: false
		        // revision: 0,                             // default: 0

		        gui_options: {
		            consent_modal: {
		                layout: 'box',                      // box,cloud,bar
		                position: 'bottom right',           // bottom,middle,top + left,right,center
		                transition: 'slide'                 // zoom,slide
		            },
		            settings_modal: {
		                layout: 'box',                      // box,bar
		                // position: 'left',                // right,left (available only if bar layout selected)
		                transition: 'slide'                 // zoom,slide
		            }
		        },

		        onFirstAction: function(user_preferences){
		        	console.log(user_preferences);
		        },

		        onAccept: function (cookie) {
		        	
		        },

		        onChange: function (cookie, changed_preferences) {
		            console.log('onChange fired ...');
		        },

		        languages: {
	                'it': {
	                    consent_modal: {
	                        title: ' Questo sito web fa uso di cookie.',
	                        description: 'Utilizziamo cookie tecnici necessari al funzionamento di questo sito web, registrazione e acquisto accrediti. Proseguendo nella navigazione si accetta l’uso dei cookie; in caso contrario non è possibile proseguire con la navigazione.',
	                        primary_btn: {
	                            text: 'Accetta tutto',
	                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
	                        },
	                        secondary_btn: {
	                            text: 'Impostazioni',
	                            role: 'settings'        // 'settings' or 'accept_necessary'
	                        }
	                    },
	                    settings_modal: {
	                        title: 'Ciné Giornate',
	                        save_settings_btn: 'Salva impostazioni',
	                        accept_all_btn: 'Accetta tutto',
	                        close_btn_label: 'Chiudi',
	                        cookie_table_headers: [
	                            {col1: 'Nome'},
	                            {col2: 'Dominio'},
	                            {col3: 'Scadenza'},
	                            {col4: 'Descrizione'}
	                        ],
	                        blocks: [
	                            {
	                                title: 'Cookie utilizzati 📢',
	                                description: 'Usiamo i cookie per garantire le funzionalità di base del sito Web e per migliorare la tua esperienza online. Per maggiori dettagli relativi ai cookie e ad altri dati sensibili, leggi l\'intera <a href="https://accrediti.cinegiornate.it/privacy-policy/" class="cc-link">informativa sulla privacy</a>.'
	                            }, {
	                                title: 'Cookies strettamente necessari',
	                                description: 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie il sito non funzionerebbe correttamente.',
	                                toggle: {
	                                    value: 'necessary',
	                                    enabled: true,
	                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
	                                }
	                            }
	                        ]
	                    }
	                }
	            }
			});

		}


	});

})();



